import React from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  theme,
  Container,
  SimpleGrid,
  Image,
  Flex,
  Heading,
  Stack,
  StackDivider,
  Icon,
  useColorModeValue,
} from '@chakra-ui/react';
import {
  IoAnalyticsSharp,
  IoLogoBitcoin,
  IoSearchSharp,
} from 'react-icons/io5';

const Feature = ({ text, icon, iconBg }) => {
  return (
    <Stack direction={'row'} align={'center'}>
      <Flex
        w={8}
        h={8}
        align={'center'}
        justify={'center'}
        rounded={'full'}
        bg={iconBg}
      >
        {icon}
      </Flex>
      <Text fontWeight={600}>{text}</Text>
    </Stack>
  );
};

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Box
        as="iframe"
        src="https://www.youtube.com/embed/JGGrB8JnVQg"
        width="100%"
        sx={{
          aspectRatio: '16/9',
        }}
      />
    </ChakraProvider>
  );
}

export default App;
